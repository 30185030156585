import React from 'react'
import Nav from '../nav'

const Header = () => {
    return (
        <header>
            <Nav />
        </header>
    )
}

export default Header