import React from 'react';
import "../../assets/css/AuditSection.css";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const AuditSection = () => {
  return (
    <div className="audit-section">
      <div className="audit-header">
        <h2>Work</h2>
        <p className="audit-description">
          Audits are not silver bullets, but an in-depth high-quality one drastically decreases the chances of getting hacked.
        </p>
      </div>

      {/* Using CSS Grid */}
      <div className="audit-cards grid-container">
        <div className="audit-card blue-gradient">
          <h3>&gt;1000</h3>
          <p>Critical, High & Medium vulnerabilities discovered by our team members</p>
        </div>

        <div className="audit-card">
          <h3>Audit stats</h3>
          <p></p>
          <ul>
            <li><span className="bullet blue"></span>35 audits</li>
            <li><span className="bullet orange"></span>40,000 nSLOC</li>
            <li><span className="bullet red"></span>32 Critical</li>
            <li><span className="bullet orange"></span>50 High</li>
            <li><span className="bullet grey"></span>100 Medium</li>
          </ul>
        </div>

        {/* <div className="audit-card trusted-by">
          <h3>Trusted by</h3>
          <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={3000}>
            <div><img src="path-to-logo-karak.png" alt="Karak" /></div>
            <div><img src="path-to-logo-reya.png" alt="Reya" /></div>
            <div><img src="path-to-logo-sofamom.png" alt="Sofamom" /></div>
            <div><img src="path-to-logo-bob.png" alt="Bob" /></div>
          </Carousel>
        </div> */}

        <div className="audit-card">
          <h3>&gt;20</h3>
          <p>Senior Security Researchers</p>
        </div>

        <div className="audit-card">
          <h3>&gt;$800M (USD)</h3>
          <p>in TVL held by audited contracts</p>
        </div>

        <div className="audit-card">
          <h3>&gt;60% audits</h3>
          <p>reported and fixed Critical/High issues</p>
        </div>
      </div>
    </div>
  );
};

export default AuditSection;
