import React from 'react'
import TeamHero from '../../components/team-hero'

const Team = () => {
    return (
        <>
            <TeamHero />
        </>
    )
}

export default Team