import React from 'react';
import logo1 from "../../assets/img/l1.png";
import logo2 from "../../assets/img/l2.png";
import logo3 from "../../assets/img/l3.png";
import logo4 from "../../assets/img/l4.png";
import logo5 from "../../assets/img/l5.png";
import logo6 from "../../assets/img/l6.png";
import logo7 from "../../assets/img/l7.png";
import logo8 from "../../assets/img/l8.png";
import logo9 from "../../assets/img/l9.png";
import logo10 from "../../assets/img/l10.png";
import logo11 from "../../assets/img/l11.png";
import logo12 from "../../assets/img/l12.png";
import logo13 from "../../assets/img/l13.png";
import logo14 from "../../assets/img/l14.png";
import logo15 from "../../assets/img/l15.png";

const TrustedBy = () => {
    const data = [
        { id: 1, img: logo1 },
        { id: 2, img: logo2 },
        { id: 3, img: logo3 },
        { id: 4, img: logo4 },
        { id: 5, img: logo5 },
        { id: 6, img: logo6 },
        { id: 7, img: logo7 },
        { id: 8, img: logo8 },
        { id: 9, img: logo9 },
        { id: 10, img: logo10 },
        { id: 11, img: logo11 },
        { id: 12, img: logo12 },
        { id: 13, img: logo13 },
        { id: 14, img: logo14 },
        { id: 15, img: logo15 },
    ];

    return (
        <div className="py-8"> {/* Reduced padding from py-[65px] to py-8 */}
            <div className="container mx-auto">
                <div className="text-center mb-8"> {/* Reduced margin */}
                    <p className="text-white text-lg font-semibold">
                        Trusted By
                    </p>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-8 items-center justify-items-center"> {/* Adjusted grid and gap */}
                    {data.map((item) => (
                        <div key={item.id} className="w-24 h-24 flex items-center justify-center"> {/* Reduced logo size */}
                            <img 
                                src={item.img} 
                                alt={`Logo ${item.id}`} 
                                className="w-full h-full object-contain" 
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TrustedBy;
